import React from 'react';

const Testimonials = (props) => {
        // console.log(props)
        return (
            <div id={props.data.primary.link_destination} className="testimonials">
                <div className="wrapper">
                    <h2>{props.data.primary.heading}</h2>
                    <div className="row">
                        {props.data.items.map((testimonial, index)=>{
                            let even="even-"+index;
                                return (
                                    <div className="col-md-6" key={index}>
                                        <div className={"box "+even}>
                                            <p>{testimonial.text}</p>
                                        </div>
                                    </div>
                                )
                        })}
                        
                    </div>
                </div>
            </div>
            );
}

export default Testimonials;