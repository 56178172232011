import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const FeaturePanel = (props) => {
        // console.log(props)
        return (
            <div id={props.data.primary.link_destination} className="feature-panel">
                <div className="wrapper">
                    <h2>{props.data.primary.feature_title}</h2>
                    <div className="row">
                        {props.data.items.map((feature, index)=>{
                            let even="";
                            if(index %2) {
                                even="even";
                            }
                                return (
                                    <div className="col-md-6" key={index}>
                                        <div className={"box "+even}>
                                            <h1>{feature.number}</h1>
                                            <RichText render={feature.descriptiom} linkResolver={linkResolver} /> 
                                        </div>
                                    </div>
                                )
                        })}
                        
                    </div>
                </div>
            </div>
            );
}

export default FeaturePanel;