import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const TextOverBackground = (props) => {
        // console.log(props)

        return (
            <div id={props.data.primary.link_destination} className="text-over-image" style={{backgroundImage: 'url('+props.data.primary.background.url+')'}}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-md-7 col-xl-5">
                            <h2>{props.data.primary.heading}</h2>
                            <RichText render={props.data.primary.text} linkResolver={linkResolver} /> 
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default TextOverBackground