import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const ContactPanel = (props) => {
        // console.log(props)

        return (
            <div className="contact-panel" id={props.data.primary.link_destination}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-md-5 large">
                        <RichText render={props.data.primary.column_one} linkResolver={linkResolver} /> 
                        </div>
                        <div className="col-md-3">
                            <RichText render={props.data.primary.column_two} linkResolver={linkResolver} /> 
                            <br/>
                        </div>
                        <div className="col-4 col-md-2">
                            <a href={props.data.primary.logo_link.url} target="_blank">
                                <img src={props.data.primary.logo.url} alt={props.data.primary.logo.alt} /> 
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default ContactPanel