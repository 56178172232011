// In src/pages/Page.js
import React, { useEffect, useRef, useState,Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { client } from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import { TwoColumnText, Banner, TitleImage, FeaturePanel, TextImage, Testimonials, ImageText, TextOverBackground, Header, StatisticsPanel, ContactPanel } from '../components'

const Page = ({ match }) => {
  const [doc, setDocData] = useState(null)
  const uid = match.params.uid
  const [navItems, setNavItems] = useState(null)
  const [menuState, togglemenuState] = useState(false)
  const [sticky, setSticky] = useState("")
  const navPanel = useRef(null)

  let dataSet =[]

  const toggleMenu = () => {
    console.log("hello")
    if(menuState === true) {
      togglemenuState(false);
      navPanel.current.classList.remove("open");
    }
    else {
      togglemenuState(true)
      navPanel.current.classList.add("open");
    }
  }

  // Get the page document from Prismic
  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 60){
        setSticky("sticky")
      } else {
        setSticky("")
      }
    })
    const fetchData = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('page', uid)
        // console.log(uid)
      if (result) {
        // console.log('doc',result)
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchData()
   
  }, [uid]) // Skip the Effect hook if the UID hasn't changed

  if (doc) {
    console.log('page: ', doc)
    doc.data.body.map((module, index) => { 
        switch(module.slice_type) {
          case 'menu_bar':
            dataSet[index] = <Header data={module} key={index} stickyState={sticky}/>
          break;
          case 'banner':
            dataSet[index] = <Banner data={module} key={index}/>
          break;
          case 'two_columns_text':
            dataSet[index] = <TwoColumnText data={module} key={index}/>
          break;
          case 'title_and_image':
            dataSet[index] = <TitleImage data={module} key={index}/>
          break;
          case 'feature_panel':
            dataSet[index] = <FeaturePanel data={module} key={index}/>
          break;
          case 'text_and_image':
            dataSet[index] = <TextImage data={module} key={index}/>
          break;
          case 'image_and_text':
            dataSet[index] = <ImageText data={module} key={index}/>
          break;
          case 'text_over_background':
            dataSet[index] = <TextOverBackground data={module} key={index}/>
          break;
          case 'statistics_panel':
            dataSet[index] = <StatisticsPanel data={module} key={index}/>
          break;
          case 'testimonials':
            dataSet[index] = <Testimonials data={module} key={index}/>
          break;
          case 'contact_panel':
            dataSet[index] = <ContactPanel data={module} key={index}/>
          break;
          default: 
        }
        return null;
    });
    return (
      <Fragment>
        <Helmet>
          {/* <meta property="og:title" content={doc.data.seo_title}/>
          <meta property="og:description" content={doc.data.seo_description} />
          <meta property="og:image" content="https://eiso.co.nz/images/eiso.jpg" />
          <meta property="og:url" content={'https://eiso.co.nz/' + doc.data.uid} />
          <meta name="twitter:card" content="https://keegan.co.nz/images/eiso.jpg" />
          <meta property="og:site_name" content={doc.data.seo_title}/>
          <meta name="twitter:image:alt" content={doc.data.seo_description}/>
          <title>{'Eiso | '+doc.data.page_title[0].text}</title> */}
          <body className={uid==="home" || uid==="about" ? "" : "header-fix"}></body>
        </Helmet>
          {dataSet.map((module, index) => {
              return module;
          })}
      </Fragment>
    )
        }
    else {
      return (
        null
      )
    }
}

export default Page