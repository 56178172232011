import React, {useRef, useState} from 'react';
import {ReactComponent as MenuBars} from '../assets/images/menu-bars.svg';

const Header = (props) => {
        console.log(props)

        const mobileMenu = useRef(null)
        const [menuState, toggleMenuState] = useState("")
        
        const toggleMenu = () => {
            if(menuState === "open") {
                toggleMenuState("");
            }
            else {
                toggleMenuState("open");
            }
            return false;
        }

        function executeScroll(id) {
            document.getElementById(id).scrollIntoView({block: 'start', behavior: "smooth"});
            toggleMenuState("");
        }

        return (
            <div id={props.data.primary.link_destination} className={"header " +props.stickyState+" "+props.pageStyle}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-2 logo">
                            <a href="/"><img src={props.data.primary?.logo?.url} style={{height: 40, width: 'auto'}}/></a>
                        </div> 
                        <div className="col-10 desktop">
                                <ul>
                                {props.data.items.map((link, index)=>{
                                    return (
                                            <li key={index} onClick={()=> executeScroll(link.link_destination)}>{link.link_text}</li>
                                        )
                                })}
                            </ul>
                        </div>
                        <div className="col-10 mobile text-right">
                                <ul>
                                    <li onClick={toggleMenu}>
                                        <MenuBars stroke="#ffffff" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                <div className={"product-menu mobile "+menuState} ref={mobileMenu}>
                <ul>
                                {props.data.items.map((link, index)=>{
                                    return (
                                            <li key={index} onClick={()=> executeScroll(link.link_destination)}>{link.link_text}</li>
                                        )
                                })}
                            </ul>
                </div>
                </div>
            );
}

export default Header;