import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const Banner = (props) => {
        // console.log(props)
        return (
            <div id={props.data.primary.link_destination}className="banner" style={{backgroundImage: 'url('+props.data.primary.background_image.url+')'}}>
                <div className="banner-color"></div>
                <div className="wrapper">
                    <div className="row align-bottom">
                        <div className="col-md-10">
                            <RichText render={props.data.primary.banner_heading} linkResolver={linkResolver} /> 
                        </div>
                    </div>
                </div>
            </div>
            );
}

export default Banner;