import React from 'react';
import { NumberPanel } from './index'

const StatisticsPanel = (props) => {
        // console.log(props)

        let years = props.data.items[2].number.split(" ");


        return (
            <div id={props.data.primary.link_destination} className="statistics-panel">
                <div className="wrapper">
                    <h2>{props.data.primary.heading}</h2>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="number-panel number-text">
                                <div className="number">
                                    <h1>{props.data.items[0].number}</h1>
                                </div>
                                <div className="text">
                                    <p>{props.data.items[0].text}</p>
                                </div>
                            </div>
                            <div className="number-panel number-text">
                                <div className="number">
                                    <h1>{props.data.items[1].number}</h1>
                                </div>
                                <div className="text">
                                    <p>{props.data.items[1].text}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="number-panel text-number">
                                    <div className="text">
                                        <p>{props.data.items[2].text}</p>
                                    </div>
                                    <div className="number">
                                        <h1>{years[0]}</h1>
                                        <p>{years[1]}</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="number-panel number-text">
                                <div className="number">
                                    <h1>{props.data.items[3].number}</h1>
                                </div>
                                <div className="text">
                                    <p>{props.data.items[3].text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="number-panel no-number-text">
                                <div className="text">
                                    <p>{props.data.items[4].text}</p>
                                    <img src={props.data.items[4].image1.url} alt={props.data.items[4].image1.alt} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="number-panel no-number-text-image-right">
                                <div className="text">
                                    <p>{props.data.items[5].text}</p>
                                    <img src={props.data.items[5].image1.url} alt={props.data.items[5].image1.alt} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="number-panel no-number-text-image-left">
                                <div className="image">
                                    <img src={props.data.items[6].image1.url} alt={props.data.items[6].image1.alt} />
                                </div>
                                <div className="text">
                                    <p>{props.data.items[6].text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                        <div className="number-panel number-text padding">
                                <div className="number">
                                    <h1>{props.data.items[7].number}</h1>
                                </div>
                                <div className="text">
                                    <p>{props.data.items[7].text}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                        <div className="number-panel no-number-text-no-image">
                                <div className="text">
                                    <p>{props.data.items[8].text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default StatisticsPanel