import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const TitleImage = (props) => {
        // console.log(props)

        return (
            <div id={props.data.primary.link_destination} className="title-image">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-sm-12 col-md-11 col-lg-11 col-xl-9">
                            <RichText render={props.data.primary.section_text} linkResolver={linkResolver} /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <img src={props.data.primary.background_image.url} alt={props.data.primary.background_image.alt}/>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default TitleImage