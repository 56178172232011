import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const TwoColumnText = (props) => {
        // console.log(props)

        return (
            <div id={props.data.primary.link_destination} className="two-column-text">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-md-4">
                            <h2>{props.data.primary.heading}</h2>
                            <RichText render={props.data.primary.intro_text} linkResolver={linkResolver} /> 
                        </div>
                        <div className="col-md-5 offset-md-2">
                        <RichText render={props.data.primary.body_copy} linkResolver={linkResolver} /> 
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default TwoColumnText